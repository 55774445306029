body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background: #fefffe;
  --text-primary: #333;
  --text-secondary: #4c191bff;
  --accent: #17cfd3ff;
  --highlight: #DDD;
  --visited-link: #607060;
}

[data-theme='dark'] {
  --background: #333;
  --text-primary: #b0c0b0;
  --text-secondary: #963d5aff;
  --accent: #17cfd3ff;
  --highlight: #555;
  --visited-link: #FFF;
}