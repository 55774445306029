ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: var(--background);
    text-align: center;
}

li {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px
}

.right {
    float: right;
    position: absolute;
    right: 0;
    padding: 0;
    text-align: right;
}

li a {
    display: inline-block;
    color: var(--text-primary);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

li button {
    display: inline-block;
    vertical-align: middle;
    color: var(--text-primary);
    background-color: inherit;
    border-style: none;
    padding: 8px 8px;
}

li button:hover {
    background-color: var(--highlight);
}

li a:hover {
    background-color: var(--highlight);
}