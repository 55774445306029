.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  transition: all .5s;
  height: 100vh;
}

.portfolio {
  background-color: var(--background);
}

.navBar:a:visited {
  color: blue;
}

a:visited {
  color: var(--visited-link);
}

.button {
  color: var(--text-primary) !important;
}

.iframe-placeholder {
  background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%2344AA70" x="50%" y="50%" font-family="\'Lucida Grande\', sans-serif" font-size="24" text-anchor="middle">Loading...</text></svg>') 0px 0px no-repeat;
}